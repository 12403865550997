import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'

const Currency = ({currentValue, open, handleClose}) => {
    const [selectedCurrency, setSelectedCurrency] = useState(currentValue)
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Para Birimi Giriniz</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Sadaka vermek istediginiz para birimini buraya yazabilirsiniz
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Para birimi"
                type="text"
                fullWidth
                variant="standard"
                value={selectedCurrency===null?'':selectedCurrency}
                onChange={(e)=>setSelectedCurrency(e.target.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleClose('')}>Iptal</Button>
            <Button onClick={() => handleClose(selectedCurrency)}>Tamam</Button>
            </DialogActions>
        </Dialog>
  )
}

export default Currency