import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import logo from './sadaka2.jpg';
import './App.css';
import Currency from './Currency';

function App() {
  
  const getTotalAmount = () => {
    if(localStorage.getItem("totalAmount") != null)
      return parseInt(localStorage.getItem("totalAmount"));
    return 0;
  }

  const getCurrency = () => {
    if(localStorage.getItem("currency") != null)
      return localStorage.getItem("currency");
    return "";
  }

  const [showModal, setShowModal] = useState(false)
  const [currency, setCurrency]  = useState(getCurrency)
  const [totalAmount, setTotalAmount] = useState(getTotalAmount)

  const sadakaVer = () => {
    setTotalAmount((prev) => {
      localStorage.setItem("totalAmount", prev+1)
      return prev + 1
    })
  }

  const reset = () => {
    setTotalAmount(0)
    localStorage.setItem("totalAmount", 0)
  }

  return (
    <div className="App">
       <Typography variant='h4' sx={{ alignItems: 'center', verticalAlign: 'center'}}>
       Bu site ne işe yarar? 
      </Typography>
      
      <Typography variant='h5' sx={{ alignItems: 'center', verticalAlign: 'center'}}>
      Bu sitenin tek amacı hergün az da olsa bir miktar sadaka vermek ve bunu 
        alışkanlık haline getirmek. 
        Niyetlendiğiniz sadaka miktarı her seferinde yerel bilgisayarınıza kaydedilir. Böylece
        bir zaman sonra vermeye niyetlendiğiniz toplam miktarı görürsünüz. 
        Zamanı gelince de  sıfırlayarak yeniden başlarsınız
      </Typography>

      
      <header className="App-header">
      <Currency currentValue= {currency} open={showModal} handleClose={(selectedCurrency)=> {
        
        if(selectedCurrency && 
          (typeof selectedCurrency === 'string' || selectedCurrency instanceof String)) {
          setCurrency(selectedCurrency)  
          localStorage.setItem("currency", selectedCurrency)
        }
        setShowModal(false)
      }}/>
       <Stack
        direction='column'
        sx={{
          alignItems: 'center',
          justifyContent:'center',
          gap: '5px'
        }}
      >
       
        <img src={logo} className="App-logo" alt="logo" />
     
      <Typography variant='h5' sx={{alignItems: 'center', verticalAlign: 'center'}}>
        Toplam: {totalAmount} {currency} 
      </Typography>
      
        <Stack 
          direction={{ sm: 'row', mx:'column'}} 
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            alignItems: 'center',
            justifyContent:'center',
            gap: '5px'
        }}>
          <Button variant="contained" color="success" sx={{width:200, height:200}}
          onClick={() => sadakaVer()}>SADAKA VER</Button>
          <Button variant="contained" color="error" sx={{width:200, height:200}}
          onClick={() => reset()}>
             SIFIRLA</Button>
        </Stack>
        <Button variant="contained" color="info" sx={{pt: "5"}}
          onClick={() => {
            setShowModal(true)         
          }}>Para Birimi Degistir</Button>
      </Stack>
      <Typography variant='body1' sx={{textAlign: 'left', alignItems: 'flex-start', verticalAlign: 'center'}}>
      (Not 1: Sadaka karşı tarafa ulaşana kadar sadece niyetinizin sevabını alırsınız)
        <br />
        (Not 2: Herhangi bir para aktarımı yapılmıyor, sadece disiplin/alışkanlık edinmenize yardımcı olunuyor). 
        <br />
        (Not 3: Sunucu tarafında herhangi bir kayıt tutulmamakta. Sadaka miktarı, 
        kullanılan internet gezginizde tutulmakta. Farklı internet tarayıcıları ya da
        farklı cihazlardan deneyerek test edebilirsiniz). 
        <br />
        (Not 4: Sadakanın yalnız mala ve paraya münhasır olmadığı bilinmesiyle ilim, fikir, kuvvet, amel gibi şeylerde de muhtaç olanlara sadaka verilebilir).
      </Typography>
      
      
        
      </header>
    </div>
  );
}

export default App;
